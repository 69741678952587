<template>
  <div id="comments">
    <post />
      <Pinned :pinned="pinned" v-if="comments.length>0"/>

      <comments-actions />

      <template v-for="comment in comments">
        <div :key="'comment-'+comment.id">
          <comment :comment="comment" parent="true" niveau="1" />
          <hr v-if="!affichageChronologique" />
        </div>
    </template>
  </div>
</template>
<script>
import Post from "@/components/Comments/Actions/Post.vue";
import Pinned from "@/components/Comments/Pinned.vue";
import Comment from "@/components/Comments/Comment.vue";
import CommentsActions from "@/components/Comments/CommentsActions.vue";
export default {
  name: "Comments",
  components: { Pinned, Comment, CommentsActions, Post },
  props: ["comments", "pinned"],
};
</script>
<style>
</style>
