<template>
<article class="annonce message is-info" v-if="texte">
  <!-- <div class="message-header">
    <p>Annonce</p>
  </div> -->
  <div class="message-body" v-html="texte">
  </div>
</article>  
</template>

<script>
export default {
    props : ['texte']
}
</script>

<style>

</style>