<template>
  <nav class="level is-mobile comments-actions">
    <div class="level-left">
      <span
        class="level-item"
        aria-label="Recharger la liste des commentaires."
        data-balloon-pos="right"
      >
        <button
          @click="$bus.$emit('load-all-comments')"
          class="button is-info is-outlined"
          :class="isLoading"
        >
          <span class="icon is-small">
            <i class="fas fa-sync"></i>
          </span>
        </button>
      </span>
      <span
        class="level-item"
        :aria-label="'Tri actuel: '+sortText"
        data-balloon-pos="right"
        v-if="story.comments>0"
      >
        <button @click="$bus.$emit('change-sort')" class="button is-info is-outlined">
          <span :class="$store.getters.sort == 'random' ? '' : 'is-hidden'">
            <span class="icon is-small">
              <i class="fas fa-random"></i>
            </span>
          </span>
          <span :class="$store.getters.sort == 'asc' ? '' : 'is-hidden'">
            <span class="icon is-small">
              <i class="fas fa-sort-amount-down-alt"></i>
            </span>
          </span>
          <span :class="$store.getters.sort == 'desc' ? '' : 'is-hidden'">
            <span class="icon is-small">
              <i class="fas fa-sort-amount-up-alt"></i>
            </span>
          </span>

        </button>
      </span>
      <span
        class="level-item"
        :aria-label="'Affichage : '+displayModeText"
        data-balloon-pos="right"
        v-if="story.comments>0"
      >
        <button @click="$bus.$emit('change-display-mode')" class="button is-info is-outlined">
          <span :class="$store.getters.displayMode == 'hierarchie' ? '' : 'is-hidden'">
            <span class="icon is-small">
              <i class="fas fa-folder-tree"></i>
            </span>
          </span>
          <span :class="$store.getters.displayMode != 'hierarchie' ? '' : 'is-hidden'">
            <span class="icon is-small">
              <i class="fas fa-clock"></i>
            </span>
          </span>
        </button>
      </span>
      <span class="level-item" v-if="isReduced">
        <button
          class="button is-info is-outlined"
          @click="$store.commit('switchOpenPost')"
          v-if="!$store.state.openPost"
        >Rédiger</button>
      </span>
    </div>
    <div>
      <template v-if="story.comments>0">
        <span class="tag">
          {{story.comments}} commentaire(s)
          <template
            v-if="story.pages>1"
          >sur {{story.pages}} page(s)</template>
        </span>
      </template>
    </div>
  </nav>
</template>
<script>
export default {
  name: "CommentsActions",
  computed: {
    sortIcon() {
      let sort = this.$store.getters.sort;
      if (sort == "random") {
        return "fa-random";
      } else if (sort == "asc") {
        return "fa-sort-amount-down-alt";
      } else {
        return "fa-sort-amount-up-alt";
      }
    },
    displayModeIcon() {
      let displayMode = this.$store.getters.displayMode;
      console.log(displayMode);
      if (displayMode == "hierarchie") {
        return "fa-folder-tree";
      } else {
        return "fa-clock";
      }
    },
    sortText() {
      let sort = this.$store.getters.sort;
      if (sort == "random") {
        return "Au hasard 🤷‍♂️";
      } else if (sort == "asc") {
        return "Plus ancien en premier";
      } else {
        return "Plus récent en premier";
      }
    },
    displayModeText() {
      let display = this.$store.getters.displayMode;
      if (display == "hierarchie") {
        return "Réponses hiérarchisées";
      } else {
        return "Flux chronologique";
      }
    },
  },
};
</script>