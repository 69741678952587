<template>
  <div id="story" v-if="storyReady">
    <link v-if="customCss" media="all" rel="stylesheet" :href="customCss" />
    <!-- <link media="all" rel="stylesheet" href="http://www.sofoot.local/css/raw/reactions.css" /> -->

    <div class="container">
      <member-recap />

      <story-annonce v-if="!isReduced" :texte="story.annonce" />

      <tabs />
      <div v-if="showComments">
        <comments :comments="comments" :pinned="pinned" />
      </div>
    </div>
    <CommentsBoutonCharger
      v-if="comments.length && showComments && pageLeft"
    />
    <br />
    <br />
  </div>
  <CommentsChargement v-else />
</template>
<script>
import MemberRecap from "@/components/Members/MemberRecap.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import StoryAnnonce from "@/components/Story/StoryAnnonce.vue";
import Comments from "@/components/Comments/Comments.vue";
import CommentsChargement from "@/components/Comments/CommentsChargement.vue";
import CommentsBoutonCharger from "@/components/Comments/CommentsBoutonCharger.vue";
export default {
  name: "Story",
  components: {
    Comments,
    Tabs,
    MemberRecap,
    CommentsChargement,
    CommentsBoutonCharger,
    StoryAnnonce,
  },
  data() {
    return {
      nombreDeChargements: 0,
      //      sort: "desc",
      type: "all",
      // page: 1,
      //      pages: false,
      // story:false,
      comments: [],
      pinned: [],
      vider: false,
    };
  },
  metaInfo() {
    return {
      title: this.storyTitle,
    };
  },
  watch: {
    $route() {
      this.comments = [];
      this.pinned = [];
      this.loadStory();
    },
  },
  mounted() {
    this.$store.commit("setSite", this.$route.params.site);

    this.$bus.$on("add-comment-racine", (comment) => {
      if(!this.comments.some(tmpComment => tmpComment.id == comment.id)) {
        if(this.$store.getters.sort == 'asc') {
          this.comments.push(comment);
        } else {
          this.comments.unshift(comment);
        }
      }
    });


    this.on("load-story", this.loadStory);

    this.on("next-page", this.nextPage);
    this.on("load-comments", this.loadComments);
    this.on("load-all-comments", this.loadAllComments);
    this.on("load-top-comments", this.loadTopComments);
    this.on("update-comments", (comments, pinned) => {
      this.comments = comments;
      this.pinned = pinned;
    });

    this.on("update-comment", (comment) => {
      this.replaceRecursive(this.comments, comment);
      this.setRecursive(this.pinned, comment);
    });

    this.on("update-comment-fields", (comment) => {
      this.setRecursive(this.pinned, comment);
      this.setRecursive(this.comments, comment);
      /*      api(`story/${this.storyId}`).then(response => {
        this.evenement("storyUpdated", response.data);
      })*/
    });

    this.on("change-sort", this.changeSort);

    this.on("change-display-mode", this.changeDisplayMode);

    this.on("change-type", this.changeType);

    this.$bus.$on("replyTo", (replyTo, quote) => {
      if (this.isConnected) {
        this.$store.commit("setReply", { replyTo, quote });
      } else {
        this.triggerEvent("logIn");
      }
    });

    /**
     * on tente de charger la story avec un delai, dans le cas où l'appel se fait
     * hors de l'iframe et qu'il ny' a donc pas de parametres envoyés par messages
     */
    setTimeout(() => {
      if (this.nombreDeChargements == 0) {
        this.loadStory();
      }
    }, 2000);
  },
  computed: {
    showComments() {
      if (
        !this.$route.params.tab ||
        this.$route.params.tab == "all" ||
        this.$route.params.tab == "top"
      ) {
        return true;
      }
    },
    storyReady() {
      return this.$store.state.site && this.story;
    },
    storyTitle() {
      let title = "";
      if ((title = this.objVal(this.story, "title"))) {
        return title;
      } else {
        return "Story";
      }
    },
    pageLeft() {
      if (!this.showComments) {
        return false;
      }
      if (this.comments.length == 0) {
        return false;
      }
      if (this.pages == 1) {
        return false;
      }
      if (this.page + 1 > this.pages) {
        return false;
      }

      return true;
    },
  },
  methods: {
    setVotes() {
      this.$store.getters.upvotes(this.storyId).forEach((commentId) => {
        this.$bus.$emit("upvote-" + commentId, true);
      });
      this.$store.getters.downvotes(this.storyId).forEach((commentId) => {
        this.$bus.$emit("downvote-" + commentId, true);
      });
    },

    // changeShowComments(showComments) {
    //   this.showComments = showComments;
    // },
    changeType(type) {
      this.type = type;
      this.$store.commit("setSort", "desc");
      this.loadAllComments();
    },
    changeDisplayMode() {
      console.log(displayMode);
      let displayMode = this.$store.getters.displayMode;
      if (displayMode == "hierarchie") {
        displayMode = "chronologie";
      } else {
        displayMode = "hierarchie";
      }
      this.$store.commit("setDisplayMode", displayMode);
      this.loadAllComments();
    },
    changeSort() {
      let sort = this.$store.getters.sort;
      if (sort == "desc") {
        sort = "asc";
      } else if (sort == "asc") {
        sort = "random";
      } else {
        sort = "desc";
      }
      this.$store.commit("setSort", sort);
      this.loadAllComments();
    },
    commentLoaded(commentId, comments = false) {
      if (!comments) {
        comments = this.comments;
      }
      return comments.some((comment) => {
        if (comment.id == commentId) {
          return true;
        }

        if (comment.answers && comment.answers.length) {
          if (this.commentLoaded(commentId, comment.answers)) {
            return true;
          }
        }
      });
    },
    nextPage(loop = null) {
      if (this.pageLeft) {
        //        this.page++;
        this.$store.commit("avancerPage");
        //        console.log(this.page);
        this.loadComments(() => {
          try {
            if (loop && loop()) {
              this.nextPage(loop);
            }
          } catch (e) {
            console.log(e);
          }
        });
      } else {
        loop();
      }
    },
    /**
     * Chargement de la story
     * On utilise smartApi qui va d'abord interroger le cdn, avant d'interoger l'API dynamique, pour récupérer la story
     * Si pas de fichier json sur le cdn : on charge dynamiquement
     * On charge aussi dynamiquement si le paramètre `chargerParCdn` est `false`
     */
    loadStory(chargerParCdn = true) {
      console.log("loadStory");
      if (!this.$store.getters.sort) {
        this.$store.commit("setSort", this.isLive ? "asc" : "desc");
      }
      if (!this.$store.getters.displayMode) {
        this.$store.commit(
          "setDisplayMode",
          this.isLive ? "chronologie" : "hierarchie"
        );
      }
      this.type = false;
      if (this.$route.params.tab == "top") {
        this.type = "top";
      } else if (!this.$route.params.tab || this.$route.params.tab == "all") {
        this.type = "all";
      }

      this.smartApi(`story/${this.storyId}`, {
        chargerParCdn,
        then: this.loadStoryThen,
        // catch: this.loadStoryCatch,
      });
    },
    /**
     * Si la story n'a pas été chargée, c'est qu'elle n'existe pas
     * Dans ce cas, si des paramètres (titre, url) ont été envoyés
     * en paramètres, la story est créée, et le 
     * chargement de la story  est relancé
     
    loadStoryCatch(error) {
      if (error.response.data.storyId) {
        if (this.$store.state.params) {
          let params = {
            storyId: error.response.data.storyId,
            title: this.$store.state.params.storyTitle,
            url: this.$store.state.params.storyUrl,
          };
          if (params.title && params.url) {
            api.post(`story`, params).then(this.loadStory);
          }
        }
      }
    },*/
    loadStoryThen(data) {
      this.setStory(data);

      this.loadAllComments(this.viserCommentaireSpecifique);
    },
    loadAllComments(commentsCallback) {
      // this.comments = [];
      this.vider = true;
      this.$store.commit("setPage", 1);
      return this.loadComments(commentsCallback);
    },
    viserCommentaireSpecifique() {
      if (this.specificCommentId) {
        let commentId = this.specificCommentId;
        this.$store.commit("setCommentId", false);
        this.nextPage(() => {
          if (!this.commentLoaded(commentId)) {
            return true;
          } else {
            setTimeout(() => {
              this.triggerEvent(
                "scrollTo",
                this.getOffsetTop('[name="comment-' + commentId + '"]')
              );
            }, 500);
          }
        });
      }
    },
    /*			loadTopComments(commentsCallback) {
				this.comments = [];
				this.type='top';
				return this.loadComments(commentsCallback);
			},*/
    loadComments(commentsCallback) {
      this.startLoading();
      if (!this.type) {
        this.type = "all";
      }
      this.smartApi(
        `story/${this.storyId}/comments/${this.type}/${this.$store.getters.sort}/${this.$store.getters.displayMode}/${this.page}`,
        {
          then: (data) => {
            this.loadCommentsThen(data, commentsCallback);
          },
          finally: this.loadCommentsFinally,
          cdnFiles: this.story.cdnFiles,
        }
      );
    },
    loadCommentsThen(data, commentsCallback) {
      if (this.vider) {
        this.vider = false;
        this.comments = [];
        this.pinned = [];
      }
      this.pinned = this.objVal(data, "pinned", []);
      this.comments = this.comments.concat(data.comments);
      this.$store.commit("setPages", data.pages);
      if (commentsCallback) {
        commentsCallback();
      }
    },
    loadCommentsFinally() {
      if (this.nombreDeChargements > 0) {
        this.triggerEvent("storyLoaded");
      }
      setTimeout(() => {
        this.setVotes();
        this.endLoading();
        this.nombreDeChargements++;
      }, 1500);
    },
    setStoryAutoLoad() {
      if (window.si_setStoryAutoLoad) {
        clearInterval(window.si_setStoryAutoLoad);
      }
      window.si_setStoryAutoLoad = setInterval(() => {
        this.$bus.$emit("load-story");
      }, 60000);
    },
  },
};
</script>

<style>
#story {
  margin: 0 0.1em;
}
</style>