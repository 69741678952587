<template>
  <div class="message mt-2" :class="'is-'+type">
    <div class="message-header" v-if="title">{{title}}</div>
    <div class="message-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "title"],
};
</script>

<style>
</style>