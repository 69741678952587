<template>
  <div class="tabs-wrapper">
    <div class="tabs is-boxed">
      <ul>
        <tab-title
          nom="all"
          title="Commentaires"
          infos="Liste des tous les commentaires"
          icone="comments"
        />
        <tab-title
          nom="top"
          title="Meilleurs commentaires"
          infos="Afficher seulement les commentaires les mieux notés"
          icone="star"
        />
        <tab-title
          v-if="afficherOngletNotifications"
          nom="notifications"
          title="Notifications"
          infos="Vos notifications"
          icone="bell"
          :amount="this.$store.state.notifications"
        />
        <tab-title
          v-if="isConnected"
          nom="reglages"
          title="Réglages"
          infos="Vos préférences"
          icone="cog"
        />
        <template v-if="isAdmin">
          <tab-title
            class="admin"
            nom="story"
            title="Story"
            infos="Administration de la story"
            icone="newspaper"
          />
          <tab-title
            class="admin"
            nom="admin"
            title="Administration"
            infos="Outils d'administration"
            icone="tachometer-alt"
          />
          <tab-title
            class="admin"
            nom="members"
            title="Membres"
            infos="Gestion des membres"
            icone="users"
          />
          <tab-title
            class="admin"
            nom="ip"
            title="Adresses IP"
            infos="Gestion des accès via adresses IP"
            icone="globe"
          />
        </template>
      </ul>
    </div>

    <component v-if="!isTabWithComments" :is="tabName" />
    <!-- <tab-reglages v-if="tab=='reglages'" /> -->
    <!-- <tab-story v-if="tab=='story'" />
    <tab-admin v-if="tab=='admin'" />
    <tab-members v-if="tab=='members'" />-->
  </div>
</template>

<script>
import TabNotifications from "@/components/Tabs/TabNotifications.vue";
import TabReglages from "@/components/Tabs/TabReglages.vue";
import TabStory from "@/components/Tabs/TabStory.vue";
import TabAdmin from "@/components/Tabs/TabAdmin.vue";
import TabMembers from "@/components/Tabs/TabMembers.vue";
import TabTitle from "@/components/Tabs/TabTitle.vue";
import TabIp from "@/components/Tabs/TabIp.vue";
export default {
  name: "Tabs",
  components: {
    TabNotifications,
    TabStory,
    TabAdmin,
    TabMembers,
    TabReglages,
    TabTitle,
    TabIp,
  },
  data() {
    return {};
  },
  watch: {
    $route() {
      this.setTab();
    },
  },
  mounted() {
    /*    this.$store.commit(
      "setNotifications",
      this.$store.state.member.notifications
    );*/

    this.setTab();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.tabs {
  overflow: hidden;
  li.admin a {
    color: #888;
  }
}
</style>
