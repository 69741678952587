<template>
  <div>
    <div class="card" v-if="currentMember">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="content">
              <div class="media">
                <div class="media-left">
                  <avatar :member="currentMember" />
                </div>
                <div class="media-content">
                  <p class="title is-4">
                    {{currentMember.name}}
                    <member-status :member="currentMember" />
                  </p>
                  <p class="subtitle is-6">
                    {{currentMember.email}}
                    <member-level :member="currentMember" />
                  </p>
                </div>
              </div>
              <p v-if="currentMember.comments">
                <small>{{currentMember.comments}} message(s)</small>
              </p>

              <template v-if="estBloque(currentMember)">
                <div class="field">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      v-model="currentMember.meta.deblocageAutomatique"
                      @change="sauver('deblocageAutomatique')"
                    />
                    Déblocage automatique
                  </label>
                </div>
                <div class="field" v-if="currentMember.meta.deblocageAutomatique">
                  <div class="control">
                    <input
                      class="input is-small"
                      type="date"
                      v-model="currentMember.meta.finBlocage"
                      @change="sauver('finBlocage')"
                    />
                <button class="button is-small is-warning mt-2" @click="notifDateDeblocage">Notifier le membre de cette date</button>
                  </div>
                </div>
              </template>

              <br />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="currentMember.meta.interditImages"
                  @change="sauver('interditImages')"
                />
                Ne peut plus poster des images
              </label>
            </div>
            <div class="field">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="currentMember.meta.voterMessages"
                  @change="sauver('voterMessages')"
                />
                Ne peux plus voter
              </label>
            </div>
            <div class="field">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="currentMember.meta.peutAfficherAvatar"
                  @change="sauver('peutAfficherAvatar')"
                />
                Peut afficher un avatar
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <todo similaires sur ip> -->
      <footer class="card-footer">
        <a @click="voirMessages" class="card-footer-item">
          <icone nom="comments-alt">Messages</icone>
        </a>
        <a @click="deBloquer" v-show="estBloque(currentMember)" class="card-footer-item">
          <icone nom="badge-check">Débloquer</icone>
        </a>
        <a @click="bloquer" v-show="!estBloque(currentMember)" class="card-footer-item">
          <icone nom="ban">Bloquer</icone>
        </a>
        <a @click="masquer" v-show="!estMasque(currentMember)" class="card-footer-item">
          <icone nom="eye-slash">Masquer</icone>
        </a>
        <a @click="demasquer" v-show="estMasque(currentMember)" class="card-footer-item">
          <icone nom="eye">Réafficher</icone>
        </a>
        <a @click="administration" class="card-footer-item">
          <icone nom="tools">Administration</icone>
        </a>
        <a @click="userIp" class="card-footer-item" v-if="member.ip">
          <icone nom="globe">{{member.ip}}</icone>
        </a>
      </footer>
    </div>
    <div v-if="comments.length">
      <div v-for="comment in comments" :key="'admin-'+comment.id">
        <hr />
        <comment :comment="comment" />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Divers/Avatar.vue";
import Comment from "@/components/Comments/Comment.vue";
export default {
  components: { Avatar, Comment },
  props: ["member"],
  data() {
    return {
      meta: {},
      currentMember: false,
      comments: [],
      page: 1,
    };
  },
  watch: {
    member() {
      this.setCurrentMember();
    },
  },
  mounted() {
    this.setCurrentMember();
  },
  computed: {},
  methods: {
    notifDateDeblocage() {
      console.log(this.convertDate(this.currentMember.meta.finBlocage))
      this.sendNotificationForMember("admin-deblocage", this.currentMember, this.dateHeureFormateeCourte(this.currentMember.meta.finBlocage));
      alert('Notification envoyée');
    },
    userIp() {
      let query = this.$route.query;
      query.ip = this.member.ip;
      let params = this.$route.params;
      params.tab = "ip";
      this.$router.push({ name: "StoryTab", params, query });
    },
    setCurrentMember(member = null) {
      this.currentMember = member ? member : this.member;
      this.currentMember.meta.peutAfficherAvatar = this.memberMeta(
        "peutAfficherAvatar",
        { default: true, member: this.currentMember }
      );
    },
    sauver(cle, callback = null) {
      let valeur;
      if (cle in this.meta) {
        valeur = this.meta[cle];
      } else {
        valeur = this.currentMember.meta[cle];
      }
      if (callback) {
        valeur = callback(valeur);
      }
      api
        .post(`member/${this.currentMember.id}/meta`, { cle, valeur })
        .then((response) => {
          this.setCurrentMember(response.data);
        });
    },

    voirMessages() {
      api
        .get(`member/comments/${this.member.id}/${this.page}`)
        .then((response) => {
          this.comments = response.data;
        });
    },
    administration() {
      this.triggerEvent("userAdmin", this.member);
    },
    masquer() {
      api.post(`members/hide/${this.member.id}`).then((response) => {
        this.currentMember.hidden = response.data.hidden;
        if (this.notifAdmin()) {
          this.sendNotificationForMember("admin-masque", this.currentMember);
        }
      });
    },
    demasquer() {
      api.post(`members/unhide/${this.member.id}`).then((response) => {
        this.currentMember.hidden = response.data.hidden;
        if (this.notifAdmin()) {
          this.sendNotificationForMember("admin-affiche", this.currentMember);
        }
      });
    },
    bloquer() {
      api.post(`members/block/${this.member.id}`).then((response) => {
        this.currentMember.active = response.data.active;
        if (this.notifAdmin()) {
          this.sendNotificationForMember("admin-bloque", this.currentMember);
        }

        const d = new Date();
        d.setDate(d.getDate() + parseInt(7));
        this.currentMember.meta.deblocageAutomatique = false;
        this.sauver("deblocageAutomatique");
        this.currentMember.meta.finBlocage = this.dateInput(d);
        this.sauver("finBlocage");
      });
    },
    deBloquer() {
      api.post(`members/unblock/${this.member.id}`).then((response) => {
        this.currentMember.active = response.data.active;
      });
    },
  },
};
</script>

<style scoped>
.card-footer {
  font-size: small;
}
</style>