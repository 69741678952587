<template>
  <div v-if="isConnected" style="position:relative">
    <transition name="fade">
      <div v-if="afficherMessage" class="reglage-message is-small message is-success">
        <div class="message-body">Modifications enregistrées</div>
      </div>
    </transition>
    <div class="columns is-mobile">
      <div class="column is-one-quarter">
        <label class="label">Notifications</label>
      </div>
      <div class="column">
        <div class="columns is-mobile">
          <div class="column">
            <label class="checkbox">
              <input type="checkbox" v-model="recevoirNotifications" @change="sauverReglages" />
              Recevoir des notifications (dans l'onglet de notifications
              <icone nom="bell" />)
            </label>
          </div>
        </div>
        <div class="ml-4" v-if="recevoirNotifications">
          <div class="columns is-mobile">
            <div class="column">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="recevoirNotificationReponse"
                  @change="sauverReglages"
                />
                Être notifié quand quelqu'un répond à un de mes messages
              </label>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <label class="checkbox">
                <input type="checkbox" v-model="recevoirNotificationVote" @change="sauverReglages" />
                Être notifié quand quelqu'un vote sur un de mes messages
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns is-mobile">
      <div class="column is-one-quarter">
        <label class="label">Avatar</label>
      </div>
      <div class="column">
        <template v-if="memberMeta('peutAfficherAvatar',{default:true})">
          <div class="columns is-mobile" v-if="!masquerAvatar">
            <div class="mr-5">
              <avatar />
            </div>
            <div class="column">
              <button
                class="button is-link is-outlined"
                @click="evenement('setAvatar')"
              >Changer d'avatar</button>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <label class="checkbox">
                <input type="checkbox" v-model="masquerAvatar" @change="sauverReglages" />
                Masquer mon avatar dans mes commentaires
              </label>
            </div>
          </div>
        </template>
        <message type="danger" title="Erreur" v-else>Vous ne pouvez plus afficher d'avatar</message>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Divers/Avatar.vue";
import Message from "@/components/Divers/Message.vue";
export default {
  components: { Avatar, Message },
  data() {
    return {
      afficherMessage: false,
      masquerAvatar: false,
      recevoirNotificationReponse: false,
      recevoirNotificationVote: false,
      recevoirNotifications: false,
    };
  },
  mounted() {
    //    this.loadToken();
    this.$store.dispatch("loadToken", {
      storyId: this.story.storyId,
    });
    this.masquerAvatar = this.memberMeta("masquerAvatar", { default: true });
    this.recevoirNotificationReponse = this.memberMeta(
      "recevoirNotificationReponse",
      { default: true }
    );
    this.recevoirNotificationVote = this.memberMeta(
      "recevoirNotificationVote",
      { default: true }
    );
    this.recevoirNotifications = this.memberMeta("recevoirNotifications", {
      default: true,
    });
  },
  methods: {
    setAfficherMessage() {
      this.afficherMessage = true;
      setTimeout(() => (this.afficherMessage = false), 3000);
    },
    sauverReglages() {
      let meta = {
        masquerAvatar: this.masquerAvatar,
        recevoirNotificationReponse: this.recevoirNotificationReponse,
        recevoirNotificationVote: this.recevoirNotificationVote,
        recevoirNotifications : this.recevoirNotifications
      };
      this.$store.dispatch("updateMemberMeta", {
        storyId: this.story.storyId,
        meta,
        callback: this.setAfficherMessage,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.reglage-message {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>