<template>
  <center>
    <br />
    <button @click="boutonAction()" class="button is-link with-hint" :class="isLoading">
      Charger plus de commentaires
      <span>Page {{page+1}}/{{pages}}</span>
    </button>
    <br />
    <br />
  </center>
</template>

<script>
export default {
  props: ["loading"],
  methods: {
    boutonAction() {
      this.$bus.$emit("next-page");
    },
  },
};
</script>

<style>
</style>