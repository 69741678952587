<template>
    <div class="notification" :class="notification.status" v-if="afficher">
      <a target="_top" :href="notification.lien"></a>
      <span v-html="notification.message"></span>
      <br />
      <small>{{notification.since}}</small>

      <button class="delete" @click="deleteNotif(notification)"></button>
      <button class="reglages">
        <icone nom="cog" />
      </button>
    </div>
</template>

<script>
export default {
  name: "Notification",
  props: ["notification"],
  data() {
    return {
      afficher:true
    }
  },
  methods : {
    deleteNotif(notification) {
      api.delete("notification/" + notification.id).then((response) => {
        this.afficher = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.notification {
  > .reglages {
      display: none;
    background: none;
    border: none;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  > .delete {
    z-index: 2;
    opacity: 0;
  }
  &:hover {
    > .delete {
      opacity: 1;
    }
  }
  .tag {
    background: white;
  }
  &.unread {
    color: inherit;
  }
  &:not(:hover).read {
    color: #888;
  }
  > a {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
</style>