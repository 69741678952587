<template>
  <div class="tab" v-if="isAdmin">
      <div class="field">
        <label class="label">Rechercher un membre</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Nom ou email"
            v-model="recherche"
            @keyup="rechercher"
          />
        </div>
      </div>
    <template v-if="members.length">
      <div v-for="resultat in members">
        <member-admin-card :member="resultat" />
        <br />
      </div>
      <center v-if="restePages">
      <br />
        <button @click="nextPage"  class="button is-link with-hint">
          Charger plus de membres
          <span>Page {{page+1}}</span>
        </button>
      </center>
    </template>
  </div>
</template>

<script>
import MemberAdminCard from "@/components/Members/MemberAdminCard.vue";
export default {
  components: { MemberAdminCard },
  data() {
    return {
      page: 1,
      recherche: "",
      members: [],
      si: false,
    };
  },
  mounted() {
    if (this.$route.query.memberId) {
      this.recherche = this.$route.query.memberId;
    }
    this.rechercher();
  },
  computed: {
    restePages() {
      return this.members.length%10==0;
    },
  },
  methods: {
    nextPage() {
      this.page++;
      this.rechercher(false);
    },
    rechercher(vider=true) {
      if(vider) {
        this.members=[];
      }
      clearTimeout(this.si);
      this.si = setTimeout(() => {
        api
          .get("members", {
            params: { search: this.recherche, page: this.page },
          })
          .then((response) => {
            this.members = this.members.concat(response.data);
          });
      }, 500);
    },
  },
};
</script>

<style>
</style>