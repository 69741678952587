<template>
  <div class="pinned" v-if="pinned.length">
    <h2 class="title is-5">Commentaires épinglés</h2>
    <div v-for="comment in pinned" :key="'pinned-'+comment.id">
      <comment :comment="comment" v-if="comment.pinned" niveau="1" :disabled="true"/>
    </div>
    <hr>
  </div>
</template>
    <br />
  </div>
</template>

<script>
import Comment from "@/components/Comments/Comment.vue";

export default {
  components: { Comment },
  name: "Pinned",
  props: ["pinned"],
};
</script>

<style lang="scss">
</style>