<template>
  <div class="tab">
    <h2 class="title">Adresses IP</h2>
    <form @submit.prevent="rechercher">
      <div class="field has-addons">
        <div class="control">
          <input class="input" type="text" placeholder="Adresse IP" v-model="ip" />
        </div>
        <div class="control">
          <button class="button is-info">Rechercher</button>
        </div>
      </div>
    </form>
    <template v-if="loaded">
      <div class="card mt-5" v-if="ipData">
        <div class="card-image">
          <figure class="image">
            <l-map
              style="height: 300px; width: 100%"
              :zoom="map.zoom"
              :center="map.center"
              @update:zoom="zoomUpdated"
              @update:center="centerUpdated"
              @update:bounds="boundsUpdated"
            >
              <l-tile-layer :url="map.url"></l-tile-layer>
              <l-marker :lat-lng="map.center">
                <l-popup>
                  <b>
                    {{ipData.city}}, {{ipData.zip}}
                    <br />
                    {{ipData.region_name}}
                  </b>
                  <br />
                  {{ipData.country_name}}, {{ipData.continent_name}}
                </l-popup>
              </l-marker>
            </l-map>
          </figure>
        </div>

        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img :src="ipData.location.country_flag" :alt="ipData.country_name" />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{{ipData.city}}, {{ipData.region_name}}</p>
              <p class="subtitle is-6">{{ipData.country_name}}, {{ipData.continent_name}}</p>
            </div>
          </div>

          <div class="content">
            {{ipData.ip}} ({{ipData.type}})
            <a
              :href="'https://whatismyipaddress.com/ip/'+ipData.ip"
              target="blank"
            >Voir plus de détails</a>
          </div>

          <button
            v-if="ipBloquee"
            type="button"
            @click="debloquer"
            class="button is-warning"
          >👍 Débloquer cette ip</button>
          <button v-else type="button" @click="bloquer" class="button is-danger">🔥 Bloquer cette ip</button>
        </div>
      </div>

      <div>
        <h2 class="title mt-5">Membres associés à l'ip</h2>
        <template v-if="members.length">
          <div v-for="member in members">
            <member-admin-card :member="member" />
          </div>
        </template>
        <template v-else>
          <i>Aucun membre trouvé</i>
        </template>
      </div>

      <div>
        <h2 class="title mt-5">Messages associés à l'ip</h2>
        <template v-if="comments.length">
          <div v-for="comment in comments" :key="'ip-'+comment.id" >
            <hr>
            <comment :comment="comment" />
          </div>
          <center v-if="restePages">
            <br />
            <button type="button" @click="nextPage" class="button is-link with-hint">
              Charger plus de messages
              <span>Page {{page+1}}</span>
            </button>
          </center>
        </template>
        <template v-else>
          <i>Aucun message trouvé</i>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import MemberAdminCard from "@/components/Members/MemberAdminCard.vue";
import Comment from "@/components/Comments/Comment.vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    MemberAdminCard,
    Comment,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      loaded: false,
      ip: "",
      ipBloquee: false,
      ipData: false,
      page: 1,
      comments: [],
      members: [],
      map: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        zoom: 8,
        center: [],
        bounds: null,
      },
    };
  },
  mounted() {
    if ((this.ip = this.$route.query.ip)) {
      this.rechercher();
    }
  },
  computed: {
    restePages() {
      return this.comments.length % 10 == 0;
    },
  },
  methods: {
    bloquer() {
      api.post("ip/" + this.ip).then((response) => {
        this.ipBloquee = true;
      });
    },
    debloquer() {
      api.delete("ip/" + this.ip).then((response) => {
        this.ipBloquee = false;
      });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    nextPage() {
      this.page++;
      this.chargerCommentaires();
    },
    rechercher() {
      this.loaded = false;
      this.ipData = false;
      ip.get(this.ip).then((response) => {
        if (response.data.type) {
          this.ipData = response.data;
          this.map.center = [this.ipData.latitude, this.ipData.longitude];
        }
      });
      api.get("ip/" + this.ip).then((response) => {
        this.loaded = true;
        this.ipBloquee = response.data;
      });
      api.get(`members/${this.ip}`).then((response) => {
        this.members = response.data;
      });
      this.comments = [];
      this.chargerCommentaires();
    },
    chargerCommentaires() {
      api.get(`ip/comments/${this.ip}/${this.page}`).then((response) => {
        this.comments = this.comments.concat(response.data);
      });
    },
  },
};
</script>

<style>
</style>