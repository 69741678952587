<template>
  <div class="notifications" v-if="notifications">
    <p>
      <a @click="chargerNotifications">Rafraîchir</a>
      &middot;
      <a @click="changeTab('reglages')">Préférences de notifications</a>
    </p>
    <p class="mb-4" v-if="notifications.length">{{pluriel(notifications.length,'notification')}}, {{pluriel($store.state.notifications,'non lue')}}</p>

    <template v-for="notification in notifications">
      <notification :key="'notification-'+notification.id" :notification="notification" />
    </template>
  </div>
</template>

<script>
import Notification from "@/components/Divers/Notification.vue";
export default {
  components: { Notification },
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {
    this.chargerNotifications();
  },
  methods: {
    chargerNotifications() {
      this.notifications = [];
      api.get("notifications").then((response) => {
        this.notifications = response.data;
        this.setStatusToRead();
      });
    },
    setStatusToRead() {
      let unread = false;
      let total = 0;
      this.notifications.forEach((notification) => {
        if (notification.status == "unread") {
          unread = true;
          total++;
        }
      });
      this.$store.commit("setNotifications", total);
      if (unread) {
        api.patch("notifications", { notifications: this.notifications });
      }
    },
  },
};
</script>

<style lang="scss">
.notifications {
}
</style>